.nav-tabs-container {
    width: 100%;
    padding: 1rem 0 0 0;
    
    display: flex;
    justify-content: center;

    display: none;

    @include md {
        display: flex;
    }
}