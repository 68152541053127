.summary-list-section {
    width: 100%;
    justify-content: space-around;
    margin-top: 2rem;

    display: none;

    @include md {
        display: flex;
    }

    .summary-list {
        width: 100%;
    
        .summary-list-title {
            font-weight: bold;
            text-align: center;
            margin: 0.5rem 1% 1rem 1%;
        }

        .summary-list-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    .summary-element {
        position: relative;
        margin: 0.5rem 1%;
        padding: 1.5rem 2rem;
        width: 69%;
        width: calc(78% - 4rem);
        background-color: white;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 10px;

        .element {
            .element-child-container {
                margin-left: 10px;

                .element-child-child-container {

                    &.disabled {
                        opacity: 1;
                    }
                    
                    .checkbox {
                        display: none;
                    }
                }
            }
        }
    }
}